li {
  font-size: 1rem;
  line-height: 1.2rem;
  vertical-align: middle;
}

ul {
  margin-left:0px;
  padding-left: 20px;
}

.messageIcon {
  line-height: 1.2rem;
  vertical-align: middle;
}

.spacer1 {
  width: 5px;
  display:inline-block;
}

.timestamp {
  display:inline-block;
  color: #666;
  font-size: 0.8rem;
}

.cardClaimButton {
  float: right;
}

.quantityOutOfStock {
  color: #f00;
}