.navBar {
  background-size: cover;
  background-image: url(/images/background.jpg);
  background-color: #8f8f8f;
}

.navBarTitle {
  font-size: 1.4rem;
}

.navBarBrand {

}
