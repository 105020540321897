.body {
  margin: 50px;
  margin-top: 100px;
}

.grid {
  margin: 0 auto;
}

.grid-item {
  margin-bottom: 10px;
  min-width: 250px;
}

.grid-item--width2 {
}