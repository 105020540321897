@import url(https://fonts.googleapis.com/css2?family=Abel:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  font-family: Abel;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spacer1 {
  margin-bottom: 10px;
}

.spacer2 {
  margin-bottom: 50px;
}
.spacer1 {
  margin-bottom: 10px;
}

.spacer2 {
  margin-bottom: 50px;
}

.sep1 {
  margin-bottom: 20px;
}

.messageText {
  font-style: italic;
}

.messageTitle {
  margin-top: 30px;
}

.messageDialogIcon {
  margin-left: auto;
  margin-right: 30px;
  margin-top: 30px;
}


li {
  font-size: 1rem;
  line-height: 1.2rem;
  vertical-align: middle;
}

ul {
  margin-left:0px;
  padding-left: 20px;
}

.messageIcon {
  line-height: 1.2rem;
  vertical-align: middle;
}

.spacer1 {
  width: 5px;
  display:inline-block;
}

.timestamp {
  display:inline-block;
  color: #666;
  font-size: 0.8rem;
}

.cardClaimButton {
  float: right;
}

.quantityOutOfStock {
  color: #f00;
}
.navBar {
  background-size: cover;
  background-image: url(/images/background.jpg);
  background-color: #8f8f8f;
}

.navBarTitle {
  font-size: 1.4rem;
}

.navBarBrand {

}


.brand {
  font-weight: 50px;
}

.jumbotron .brand {
  font-size: 2.5rem;
  text-shadow: 1px 1px 5px #ffffff;
}

.jumbotron .brand-com {
  font-size: 2.5rem;
}

.navBar .brand {
  font-size: 1.0rem;
  text-shadow: 1px 1px 5px #ffffff;
}

.navBar .brand-com {
  font-size: 1.0rem;
  text-shadow: 1px 1px 5px #ffffff;
}

.brand-virtual {
  color: #f70057;
}
.brand-pastries {
  color: #f70057;
}
.brand-com {
  color: #000000;
}

a {
  text-decoration: none;
}
.spacer1 {
  margin-bottom: 10px;
}

.spacer2 {
  margin-bottom: 50px;
}
.body {
  margin: 50px;
  margin-top: 100px;
}

.grid {
  margin: 0 auto;
}

.grid-item {
  margin-bottom: 10px;
  min-width: 250px;
}

.grid-item--width2 {
}
body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.background {
  position: absolute;
  background-size: cover;
  background-image: url(/images/background.jpg);
  height: 100%;
  width: 100%;
  margin: 0;
}

.jumbotron {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  padding: 25px;
  text-align: left;
  background-color: #ffffffff;
  border-radius: 25px;
}

.jumbotron p {
  font-size: 1.2rem;
  margin: 25px;
}

.jumbotron button {
  float: right;
}

#spacer1 {
  height: 5px;
}

