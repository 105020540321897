@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.background {
  position: absolute;
  background-size: cover;
  background-image: url(/images/background.jpg);
  height: 100%;
  width: 100%;
  margin: 0;
}

.jumbotron {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  padding: 25px;
  text-align: left;
  background-color: #ffffffff;
  border-radius: 25px;
}

.jumbotron p {
  font-size: 1.2rem;
  margin: 25px;
}

.jumbotron button {
  float: right;
}

#spacer1 {
  height: 5px;
}
