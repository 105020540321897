
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.brand {
  font-weight: 50px;
}

.jumbotron .brand {
  font-size: 2.5rem;
  text-shadow: 1px 1px 5px #ffffff;
}

.jumbotron .brand-com {
  font-size: 2.5rem;
}

.navBar .brand {
  font-size: 1.0rem;
  text-shadow: 1px 1px 5px #ffffff;
}

.navBar .brand-com {
  font-size: 1.0rem;
  text-shadow: 1px 1px 5px #ffffff;
}

.brand-virtual {
  color: #f70057;
}
.brand-pastries {
  color: #f70057;
}
.brand-com {
  color: #000000;
}

a {
  text-decoration: none;
}