.spacer1 {
  margin-bottom: 10px;
}

.spacer2 {
  margin-bottom: 50px;
}

.sep1 {
  margin-bottom: 20px;
}

.messageText {
  font-style: italic;
}

.messageTitle {
  margin-top: 30px;
}

.messageDialogIcon {
  margin-left: auto;
  margin-right: 30px;
  margin-top: 30px;
}

